import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ContentFront from '../../components/Layout/ContentFront'

class Products extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allNodeProduct(filter: {promote: {eq: true}}, limit: 1, sort: {fields: changed, order: DESC}) {
              edges {
                node {
                  id
                  title
                  field_teaser
                  promote
                  displayDate: created(formatString: "MM/DD/Y")
                  path {
                    alias
                  }
                  relationships {
                    featuredImage: field_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 125, maxHeight: 125, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}

        render={data => (
          <section>
            {data.allNodeProduct.edges.map(product => (
              <>
                <ContentFront data={product.node} title="Product Review" link="/products" />
              </>
            ))}
          </section>
        )}
      />
    )
  }
}

export default Products