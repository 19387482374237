import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { Link, StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

class Comic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }

  handleModalOpen = () => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allNodeComics {
              edges {
                node {
                  id
                  relationships {
                    field_image {
                      localFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    field_image_teaser {
                      localFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}

        render={data => {
          const day = new Date().getDate() - 1
          const currentComic = data.allNodeComics.edges[day]
          return (
            <section >
              <div>
                <div className="text-white px-2 p-1 text-sm bg-blue-700">Daily Dirt Clods</div>
                <div style={{ maxWidth: '100%', width: '200px' }} className="mx-auto mt-2 pb-3 relative">
                  <Link to="#" onClick={this.handleModalOpen}>
                    <Img
                      fluid={currentComic.node.relationships.field_image_teaser.localFile.childImageSharp.fluid}
                      style={{ maxWidth: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                    />
                    <div className="absolute right-0 bottom-0 text-xs border p-1 bg-gray-600 no-underline text-white mb-2">click to view</div>
                  </Link>

                </div>
              </div>
              <ReactModal
                style={{
                  content: {
                    top: '50%',
                    left: '50%',
                    width: '700px',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                  }
                }}
                isOpen={this.state.isModalOpen}
                onRequestClose={this.handleModalClose}
                contentLabel="Daily Dirt Clods"
              >
                <Img
                  fluid={currentComic.node.relationships.field_image.localFile.childImageSharp.fluid}
                  style={{ maxWidth: "100%" }}
                  imgStyle={{ objectFit: "contain" }} />
                <button className="btn-small btn-blue mt-3" onClick={this.handleModalClose}>Close</button>
              </ReactModal>
            </section>
          )
        }
        }
      />
    )
  }
}

export default Comic