import React from 'react'
import PropTypes from "prop-types"
import Layout from '../components/Layout/Layout'
import Products from '../components/Front/Products'
import Articles from '../components/Front/Articles'
import Recall from '../components/Front/Recall'
import RightColumn from '../components/Layout/RightColumn'
import Repair from '../components/Front/Repair'
import SEO from "../components/seo"


const IndexPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Total Bike" />
    <section className="container mx-auto">
      <div className="md:w-3/4 md:float-left">
        <Products />
        <Repair />
        <Articles />
        <Recall />
      </div>
      <div className="md:w-1/4 md:float-right"
        style={{ clear: "right" }}>
        <RightColumn />
      </div>

    </section>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default IndexPage
