import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from 'gatsby-image'

const ContentFront = ({ data, className, title, link, location }) => (
  <div key={data.id}
    className={`shadow-md rounded-md mb-5 ${className}`}
  >
    <div className="flex">
      <div className="flex-1 flex-grow p-2 bold">
        {title &&
          <div className="text-sm font-medium">
            {title}
          </div>
        }
        <Link to={data.path.alias} className="text-xl font-semibold ">
          {data.title}
        </Link>
        {data.field_teaser &&
          <div>
            {data.field_teaser}
          </div>
        }
        {data.field_address &&
          <div className="text-sm">{data.field_address.city}, {data.field_address.state}</div>
        }
        {data.drupal_internal__nid && location?.hostname === 'localhost' &&
          <div className="text-sm">
            <a href={`https://admin.totalbike.com/node/${data.drupal_internal__nid}/edit`}
              rel="noopener noreferrer" target="_blank">
              Edit
            </a>
          </div>
        }
        {data.drupal_internal__tid && location?.hostname === 'localhost' &&
          <div className="text-sm">
            <a href={`https://admin.totalbike.com/taxonomy/term/${data.drupal_internal__tid}/edit`}
              rel="noopener noreferrer" target="_blank">
              Edit
            </a>
          </div>
        }
        <div className="text-sm">
          {link &&
            <Link to={link}>
              More {title}
            </Link>
          }
        </div>
      </div>
      {data.relationships.featuredImage.localFile.childImageSharp.fluid &&
        <div className=" p-1" style={{ width: '125px', maxHeight: '100%', height: '125px' }}>
          <Link to={data.path.alias}>
            <Img fluid={data.relationships.featuredImage.localFile.childImageSharp.fluid}
              className="items-center"
              style={{ maxHeight: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Link>
        </div>
      }
    </div>
  </div>
);

//data, className, title, link, location
ContentFront.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  title: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.object
}

export default ContentFront
