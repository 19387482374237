import React from 'react'
import Comic from '../../components/Comic'
import { Link } from 'gatsby'

const RightColumn = () => {
  return (
    <section>
      <div className="mx-2 md:ml-10 shadow rounded-md mb-4">
        <Comic className="mx-auto" day={new Date().getDate()} />
      </div>

      <div className="mx-2 md:ml-10 shadow rounded-md mb-4">
        <div className="text-white px-2 p-1 text-sm bg-blue-700">Articles Wanted</div>
        <div className="p-2 text-sm">
          <Link to="/contact" title="Contact Total Bike">
            Send us</Link> your review, articles, stories or other writings.
      </div>
      </div>

      <div className="mx-2 md:ml-5 md:ml-10 shadow rounded-md mb-4">
        <div className="text-white px-2 p-1 text-sm bg-blue-700">About Total Bike</div>
        <div className="p-2 text-sm">
          Since 1999 Total Bike has an online resource for bicycle enthusiasts. After a long break we are back.
        </div>
      </div>
    </section>
  )
}

export default RightColumn
