import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import ContentFront from '../Layout/ContentFront'

class Recall extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allNodeRecalls(limit: 1, sort: {fields: field_recall_date, order: DESC}) {
              edges {
                node {
                  id
                  title
                  displayDate: field_recall_date(formatString: "MM/DD/Y")
                  path {
                    alias
                  }
                  field_teaser
                  relationships {
                    featuredImage: field_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 125, maxHeight: 125, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}

        render={data => (
          <section>
            {data.allNodeRecalls.edges.map(recall => (
              <>
                <ContentFront data={recall.node} title="recall" link="/recalls" />
              </>
            ))}
          </section>
        )}
      />
    )
  }
}

export default Recall